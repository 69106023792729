import React, { useState, useEffect } from "react";
import "./App.css";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { FaFileExcel } from "react-icons/fa";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import StatsModal from "./StatsModal";

// CSS styles for the header band
const headerStyles = {
  backgroundColor: "#f8f9fa",
  padding: "20px",
  marginBottom: "20px",
};

// CSS styles for the logo
const logoStyles = {
  maxWidth: "150px",
};

// Configure the AWS credentials and region
AWS.config.update({
  accessKeyId: process.env.REACT_APP_CLIENT_ID,
  secretAccessKey: process.env.REACT_APP_CLIENT_SECRET,
  region: "eu-west-1",
});

// Create an S3 instance
const s3 = new AWS.S3();
const dynamodb = new AWS.DynamoDB.DocumentClient();

const App = () => {
  const [tickets, setTickets] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    column: "",
    direction: "",
  });
  const sortedTickets = React.useMemo(() => {
    const { column, direction } = sortConfig;
    if (column && direction) {
      return [...tickets].sort((a, b) => {
        if (a[column] < b[column]) return direction === "asc" ? -1 : 1;
        if (a[column] > b[column]) return direction === "asc" ? 1 : -1;
        return 0;
      });
    }
    return tickets;
  }, [tickets, sortConfig]);
  const [showModal, setShowModal] = useState(false);
  const [newTicket, setNewTicket] = useState({
    title: "",
    description: "",
    status: "",
    photo: null,
    nameDeclarant: "",
  });
  const [originalTickets, setOriginalTickets] = useState([]);

  const [selectedTicket, setSelectedTicket] = useState(null);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [solvedTicketData, setSolvedTicketData] = useState({
    date: "",
    name: "",
    comment: "",
    solvedPhoto: "",
  });
  const [showStatsModal, setShowStatsModal] = useState(false);

  const handleSort = (column) => {
    let direction = "asc";
    if (sortConfig.column === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ column, direction });
  };

  const getSortIcon = (column) => {
    if (sortConfig.column === column) {
      if (sortConfig.direction === "asc") {
        return <FaSortUp />;
      } else {
        return <FaSortDown />;
      }
    }
    return <FaSort />;
  };

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;

    if (name === "photo") {
      setNewTicket((prevTicket) => ({
        ...prevTicket,
        photo: files[0],
      }));
    } else if (name === "solvedPhoto") {
      setSolvedTicketData((prevTicket) => ({
        ...prevTicket,
        solvedPhoto: files[0],
      }));
    } else {
      setNewTicket((prevTicket) => ({
        ...prevTicket,
        [name]: value,
      }));
    }
  };

  // Function to upload a file to S3
  async function uploadFileToS3(
    bucketName,
    fileName,
    fileContent,
    contentType
  ) {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: fileContent,
      ContentType: contentType,
    };
    console.log(params);

    const uploadResult = await s3.upload(params).promise();
    return uploadResult.Location;
  }

  // Function to generate a pre-signed URL for the uploaded file
  function generatePresignedURL(bucketName, fileName) {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Expires: 864000, // 10 days in seconds
    };
    const signedUrl = s3.getSignedUrl("getObject", params);
    return signedUrl;
  }

  const createTicketDynamoDB = async (ticket) => {
    console.log(ticket);
    const params = {
      TableName: process.env.REACT_APP_COMMAND_CENTER_DYNAMO_DB,
      Item: {
        ticket_id: uuidv4(),
        timestamp: getCurrentDateTime(),
        id: ticket.id,
        title: ticket.title,
        description: ticket.description,
        nameDeclarant: ticket.nameDeclarant,
        status: ticket.status,
        filename: ticket.filename,
        process: ticket.process,
        type: ticket.type,
        image: ticket.photo
          ? generatePresignedURL(
              process.env.REACT_APP_S3_BUCKET_IMG,
              ticket.filename
            )
          : "-",
      },
    };

    try {
      await dynamodb.put(params).promise();
      console.log("Ticket created successfully");
    } catch (error) {
      console.error("Error creating ticket:", error);
    }
  };

  const getCurrentDateTime = () => {
    const currentDateTime = new Date();
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return currentDateTime.toLocaleString("en-GB", options);
  };

  const fetchTickets = async () => {
    const params = {
      TableName: process.env.REACT_APP_COMMAND_CENTER_DYNAMO_DB,
      FilterExpression:
        "attribute_not_exists(archived) OR archived = :archivedValue",
      ExpressionAttributeValues: {
        ":archivedValue": false,
      },
    };

    try {
      const response = await dynamodb.scan(params).promise();
      const tickets = response.Items;
      setTickets(tickets);
      setOriginalTickets(tickets);
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleCreateTicket = async () => {
    const currentDate = getCurrentDate();
    const ticketId = uuidv4();
    //if (newTicket.title.trim() !== "" && newTicket.status.trim() !== "") {
    const ticket = {
      ...newTicket,
      ticket_id: ticketId,
      filename: newTicket.photo
        ? `${currentDate}-${ticketId}-${newTicket.photo.name}`
        : "-",
      timestamp: getCurrentDateTime(),
    };
    // Upload the PDF to S3
    if (newTicket.photo) {
      await uploadFileToS3(
        process.env.REACT_APP_S3_BUCKET_IMG,
        ticket.filename,
        ticket.photo,
        "application/png"
      );
    }
    console.log(ticket);
    await createTicketDynamoDB(ticket);

    setTickets((prevTickets) => [...prevTickets, ticket]);
    setOriginalTickets((prevTickets) => [...prevTickets, ticket]);
    setNewTicket({
      title: "",
      description: "",
      status: "",
      photo: null,
      nameDeclarant: "",
    });
    setShowModal(false);
    //}
  };

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
    setShowTicketModal(true);
  };

  const handleSolvedTicketInputChange = (event) => {
    const { name, value } = event.target;
    setSolvedTicketData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMarkTicketArchived = async () => {
    const updatedTicket = {
      ...selectedTicket,
      archived: true,
    };
    console.log(updatedTicket);
    const params = {
      TableName: process.env.REACT_APP_COMMAND_CENTER_DYNAMO_DB,
      Key: {
        ticket_id: updatedTicket.ticket_id,
      },
      UpdateExpression: "set #archived = :archived",
      ExpressionAttributeNames: {
        "#archived": "archived",
      },
      ExpressionAttributeValues: {
        ":archived": updatedTicket.archived,
      },
    };
    console.log(params);
    try {
      await dynamodb.update(params).promise();
      console.log("Ticket updated successfully");
    } catch (error) {
      console.error("Error updating ticket:", error);
    }

    const updatedTickets = tickets.map((ticket) =>
      ticket.id === updatedTicket.id ? updatedTicket : ticket
    );

    setTickets(updatedTickets);
    setShowTicketModal(false);
    setSolvedTicketData({
      date: "",
      name: "",
      comment: "",
      solvedPhoto: "",
    });
    fetchTickets();
  };

  const handleMarkTicketDone = async () => {
    console.log("HEEEEY");
    console.log(solvedTicketData);
    const filename = `solved-${getCurrentDateTime()}-${selectedTicket.ticketId}-${
      solvedTicketData.solvedPhoto.name
    }`;
    // Upload the PDF to S3
    if (solvedTicketData.solvedPhoto) {
      await uploadFileToS3(
        process.env.REACT_APP_S3_BUCKET_IMG,
        filename,
        solvedTicketData.solvedPhoto,
        "application/png"
      );
    }
    const updatedTicket = {
      ...selectedTicket,
      solved: true,
      solvedDate: solvedTicketData.date,
      solverName: solvedTicketData.name,
      solverComment: solvedTicketData.comment,
      solvedPhoto: generatePresignedURL(
        process.env.REACT_APP_S3_BUCKET_IMG, filename
        
      ),
    };
    console.log(updatedTicket);
    const params = {
      TableName: process.env.REACT_APP_COMMAND_CENTER_DYNAMO_DB,
      Key: {
        ticket_id: updatedTicket.ticket_id,
      },
      UpdateExpression:
        "set #solved = :solved, #solvedDate = :solvedDate, #solverName = :solverName, #solverComment = :solverComment, #status = :status, #solvedPhoto = :solvedPhoto",
      ExpressionAttributeNames: {
        "#solved": "solved",
        "#solvedDate": "solvedDate",
        "#solverName": "solverName",
        "#solverComment": "solverComment",
        "#status": "status",
        "#solvedPhoto": "solvedPhoto",
      },
      ExpressionAttributeValues: {
        ":solved": updatedTicket.solved,
        ":solvedDate": updatedTicket.solvedDate,
        ":solverName": updatedTicket.solverName,
        ":solverComment": updatedTicket.solverComment,
        ":solvedPhoto": updatedTicket.solvedPhoto,
        ":status": "Done",
      },
    };
    console.log(params);
    try {
      await dynamodb.update(params).promise();
      console.log("Ticket updated successfully");
    } catch (error) {
      console.error("Error updating ticket:", error);
    }

    const updatedTickets = tickets.map((ticket) =>
      ticket.id === updatedTicket.id ? updatedTicket : ticket
    );

    setTickets(updatedTickets);
    setShowTicketModal(false);
    setSolvedTicketData({
      date: "",
      name: "",
      comment: "",
    });
    fetchTickets();
  };

  const handleFilter = (status) => {
    if (status === "") {
      // If no status is selected, show all tickets
      setTickets(originalTickets);
    } else {
      // Filter tickets based on status
      const filteredTickets = originalTickets.filter(
        (ticket) => ticket.status === status
      );
      setTickets(filteredTickets);
    }
  };

  const handleImageClick = () => {
    const overlay = document.createElement("div");
    overlay.className = "modal-image-overlay";
    overlay.addEventListener("click", () => {
      overlay.remove();
      document.body.style.overflow = "auto"; // Restore scrolling
    });

    const image = document.createElement("img");
    image.src = selectedTicket.image;
    image.alt = "ticketimage";
    image.className = "modal-image";

    overlay.appendChild(image);
    document.body.appendChild(overlay);
    document.body.style.overflow = "hidden"; // Disable scrolling
  };

  
  const handleImageSolvedClick = () => {
    const overlay = document.createElement("div");
    overlay.className = "modal-image-overlay";
    overlay.addEventListener("click", () => {
      overlay.remove();
      document.body.style.overflow = "auto"; // Restore scrolling
    });

    const image = document.createElement("img");
    image.src = selectedTicket.solvedPhoto;
    image.alt = "ticketsolvedimage";
    image.className = "modal-image";

    overlay.appendChild(image);
    document.body.appendChild(overlay);
    document.body.style.overflow = "hidden"; // Disable scrolling
  };

  const handleExportStats = () => {};

  const handleExportToExcel = () => {
    const table = document.getElementById("ticket-table");
    const tableData = [];

    // Iterate through each row of the table
    for (let i = 0; i < table.rows.length; i++) {
      const rowData = [];
      const row = table.rows[i];

      // Iterate through each cell of the row
      for (let j = 0; j < row.cells.length; j++) {
        const cell = row.cells[j];
        rowData.push(cell.innerText);
      }

      tableData.push(rowData);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(excelData, "ory4-housekeeping-tickets.xlsx");
  };

  return (
    <div className="app">
      <div style={headerStyles}>
        <img
          src={process.env.PUBLIC_URL + "/Amazon-logo.png"}
          alt="Logo"
          style={logoStyles}
        />

        <div class="right-element">
          <strong>Amazon France Logistique SAS</strong>
          <p>Site de Brétigny-sur-Orge</p>
          <p>ORY4</p>
        </div>
      </div>
      <h1>ORY4 Housekeeping</h1>
      <button className="create-ticket-btn" onClick={() => setShowModal(true)}>
        Créer nouveau ticket
      </button>
      <div className="filter-section">
        <label>Filtrer par statut:</label>
        <select
          className="filter-select"
          onChange={(event) => handleFilter(event.target.value)}
        >
          <option value="">Tous les tickets</option>
          <option value="Ouvert">Ouvert</option>
          <option value="En cours">En cours</option>
          <option value="Done">Done</option>
        </select>
      </div>
      <div className="ticket-extract">
        <button onClick={handleExportToExcel}>
          <FaFileExcel /> Exporter en Excel
        </button>
      </div>
      <div className="ticket-stats">
        <button onClick={() => setShowStatsModal(true)}>Statistiques</button>
      </div>
      <table className="ticket-table" id="ticket-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("title")}>
              Titre ticket {getSortIcon("title")}
            </th>
            <th onClick={() => handleSort("description")}>
              Description détaillée {getSortIcon("description")}
            </th>
            <th onClick={() => handleSort("process")}>
              Process {getSortIcon("process")}
            </th>
            <th onClick={() => handleSort("type")}>
              Type {getSortIcon("type")}
            </th>
            <th onClick={() => handleSort("nameDeclarant")}>
              Nom déclarant {getSortIcon("nameDeclarant")}
            </th>
            <th onClick={() => handleSort("timestamp")}>
              Date ouverture {getSortIcon("timestamp")}
            </th>
            <th onClick={() => handleSort("status")}>
              Statut {getSortIcon("status")}
            </th>
            <th onClick={() => handleSort("solvedDate")}>
              Date clôture {getSortIcon("solvedDate")}
            </th>
            <th onClick={() => handleSort("solverName")}>
              Nom résolveur {getSortIcon("solverName")}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedTickets.map((ticket) => (
            <tr key={ticket.id} onClick={() => handleTicketClick(ticket)}>
              <td>{ticket.title ? ticket.title : "-"}</td>
              <td>{ticket.description ? ticket.description : "-"}</td>
              <td>{ticket.process ? ticket.process : "-"}</td>
              <td>{ticket.type ? ticket.type : "-"}</td>
              <td>{ticket.nameDeclarant ? ticket.nameDeclarant : "-"}</td>
              <td>{ticket.timestamp ? ticket.timestamp : "-"}</td>
              <td>{ticket.status ? ticket.status : "-"}</td>
              <td>{ticket.solvedDate ? ticket.solvedDate : "-"}</td>
              <td>{ticket.solverName ? ticket.solverName : "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {showStatsModal && (
        <StatsModal
          isOpen={showStatsModal}
          onClose={() => setShowStatsModal(false)}
          tickets={tickets} // Pass the 'tickets' state to the StatsModal component
        />
      )}

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 className="modal-title">Créer un nouveau ticket</h2>
            <div className="form-group">
              <label className="form-label">Titre:</label>
              <input
                type="text"
                name="title"
                value={newTicket.title}
                onChange={handleInputChange}
                className="modal-input"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Description:</label>
              <textarea
                name="description"
                value={newTicket.description}
                onChange={handleInputChange}
                className="modal-textarea"
              ></textarea>
            </div>
            <div className="form-group">
              <label className="form-label">Nom déclarant:</label>
              <input
                type="text"
                name="nameDeclarant"
                value={newTicket.nameDeclarant}
                onChange={handleInputChange}
                className="modal-input"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Process:</label>
              <select
                name="process"
                value={newTicket.process}
                onChange={handleInputChange}
                className="modal-select"
              >
                <option value="">Choisir un process</option>
                <option value="inbound">Inbound</option>
                <option value="stow">Stow</option>
                <option value="pick">Pick</option>
                <option value="iss">ISS</option>
                <option value="damageLand">Damage Land</option>
                <option value="Autre">Autre</option>
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">Type:</label>
              <select
                name="type"
                value={newTicket.type}
                onChange={handleInputChange}
                className="modal-select"
              >
                <option value="">Choisir un type</option>
                <option value="5sdeviation">5S Deviation</option>
                <option value="clean">Clean</option>
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">Statut:</label>
              <select
                name="status"
                value={newTicket.status}
                onChange={handleInputChange}
                className="modal-select"
              >
                <option value="">Choisir un statut</option>
                <option value="Ouvert">Ouvert</option>
                <option value="En cours">En cours</option>
                <option value="Done">Done</option>
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">Joindre photo:</label>
              <input
                type="file"
                name="photo"
                onChange={(event) => handleInputChange(event)}
                className="modal-file-input"
              />
            </div>
            <div className="modal-btn-container">
              <button className="modal-create-btn" onClick={handleCreateTicket}>
                Créer
              </button>
              <button
                className="modal-cancel-btn"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}

      {showTicketModal && selectedTicket && (
        <div className="ticket-details-modal">
          <div className="ticket-details-content">
            <h2 className="ticket-details-title">Détails du ticket</h2>
            <div className="ticket-details">
              <div className="ticket-details-row">
                <strong className="ticket-details-label">Titre:</strong>
                <p className="ticket-details-value">{selectedTicket.title}</p>
              </div>
              <div className="ticket-details-row">
                <strong className="ticket-details-label">Nom déclarant:</strong>
                <p className="ticket-details-value">
                  {selectedTicket.nameDeclarant}
                </p>
              </div>
              <div className="ticket-details-row">
                <strong className="ticket-details-label">Process:</strong>
                <p className="ticket-details-value">
                  {selectedTicket.process ? selectedTicket.process : "-"}
                </p>
              </div>
              <div className="ticket-details-row">
                <strong className="ticket-details-label">Type:</strong>
                <p className="ticket-details-value">
                  {selectedTicket.type ? selectedTicket.type : "-"}
                </p>
              </div>
              <div className="ticket-details-row">
                <strong className="ticket-details-label">Description:</strong>
                <div className="ticket-details-value">
                  <p>{selectedTicket.description}</p>
                </div>
              </div>
              <div className="ticket-details-row">
                <strong className="ticket-details-label">Pièce jointe:</strong>
                <p className="ticket-details-value">
                  <img
                    src={selectedTicket.image}
                    alt="ticketimage"
                    className="ticket-details-image"
                    onClick={handleImageClick}
                    width="50%"
                    height="50%"
                  />
                </p>
              </div>
              <div className="ticket-details-row">
                <strong className="ticket-details-label">Statut:</strong>
                <p className="ticket-details-value">{selectedTicket.status}</p>
              </div>
              {selectedTicket.status === "Done" && (
                <>
                  <div className="ticket-details-row">
                    <strong className="ticket-details-label">
                      Nom du résolveur:
                    </strong>
                    <p className="ticket-details-value">
                      {selectedTicket.solverName}
                    </p>
                  </div>
                  <div className="ticket-details-row">
                    <strong className="ticket-details-label">
                      Commentaire du résolveur:
                    </strong>
                    <p className="ticket-details-value">
                      {selectedTicket.solverComment}
                    </p>
                  </div>
                  <div className="ticket-details-row">
                    <strong className="ticket-details-label">
                      Date de résolution:
                    </strong>
                    <p className="ticket-details-value">
                      {selectedTicket.solvedDate}
                    </p>
                  </div>
                  <div className="ticket-details-row">
                    <strong className="ticket-details-label">
                      Photo résolution:
                    </strong>
                    <p className="ticket-details-value">
                      <img
                        src={selectedTicket.solvedPhoto}
                        alt="solvedPhoto"
                        className="ticket-details-solved-image"
                        onClick={handleImageSolvedClick}
                        width="50%"
                        height="50%"
                      />
                    </p>
                  </div>
                </>
              )}
              {!selectedTicket.solved && (
                <div className="ticket-details-row solve-ticket-section">
                  <h3 className="ticket-details-subtitle">
                    Clôturer le ticket
                  </h3>
                  <div className="ticket-details-input">
                    <label className="ticket-details-input-label">Date:</label>
                    <input
                      type="date"
                      name="date"
                      value={solvedTicketData.date}
                      onChange={handleSolvedTicketInputChange}
                      className="ticket-details-input-field"
                    />
                  </div>
                  <div className="ticket-details-input">
                    <label className="ticket-details-input-label">
                      Nom résolveur:
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={solvedTicketData.name}
                      onChange={handleSolvedTicketInputChange}
                      className="ticket-details-input-field"
                    />
                  </div>
                  <div className="ticket-details-input">
                    <label className="ticket-details-input-label">
                      Commentaire:
                    </label>
                    <textarea
                      name="comment"
                      value={solvedTicketData.comment}
                      onChange={handleSolvedTicketInputChange}
                      className="ticket-details-input-field"
                    ></textarea>
                    <div className="form-group">
                      <label className="form-label">Joindre photo:</label>
                      <input
                        type="file"
                        name="solvedPhoto"
                        onChange={(event) => handleInputChange(event)}
                        className="modal-file-input"
                      />
                    </div>
                  </div>
                  <button
                    className="solve-ticket-btn"
                    onClick={handleMarkTicketDone}
                  >
                    Clôturer ticket
                  </button>
                </div>
              )}
            </div>
            <button
              className="modal-close-btn"
              onClick={() => setShowTicketModal(false)}
            >
              Fermer
            </button>

            <button
              className="modal-archive-btn"
              onClick={() => handleMarkTicketArchived(false)}
            >
              Archiver ticket
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
