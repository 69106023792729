import React, { useState, useEffect, useRef } from "react";
import { Bar, Pie } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
Chart.register(CategoryScale);

const calculateChartData = (tickets) => {
    const statusCounts = {
      Ouvert: 0,
      "En cours": 0,
      Done: 0,
    };
  
    const resolvedByCounts = {};
  
    tickets.forEach((ticket) => {
      if (ticket.status === "Ouvert") statusCounts.Ouvert++;
      else if (ticket.status === "En cours") statusCounts["En cours"]++;
      else if (ticket.status === "Done") statusCounts.Done++;
  
      // Count the number of resolved tickets for each person
      if (ticket.status === "Done" && ticket.solverName) {
        if (resolvedByCounts[ticket.solverName]) {
          resolvedByCounts[ticket.solverName]++;
        } else {
          resolvedByCounts[ticket.solverName] = 1;
        }
      }
    });
  
    return {
      labels: Object.keys(statusCounts),
      datasets: [
        {
          label: "Ticket Status",
          data: Object.values(statusCounts),
          backgroundColor: ["rgba(255, 99, 132, 0.6)", "rgba(54, 162, 235, 0.6)", "rgba(75, 192, 192, 0.6)"],
          borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(75, 192, 192, 1)"],
          borderWidth: 1,
        },
      ],
      resolvedByData: {
        labels: Object.keys(resolvedByCounts),
        datasets: [
          {
            label: "Resolved Tickets by Person",
            data: Object.values(resolvedByCounts),
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              // Add more colors if needed
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(75, 192, 192, 1)",
              // Add more colors if needed
            ],
            borderWidth: 1,
          },
        ],
      },
    };
  };
  
  const StatsModal = ({ isOpen, onClose, tickets }) => {
    const chartRef = useRef(null);
    const pieChartRef = useRef(null);
  
    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.data = calculateChartData(tickets);
        chartRef.current.update();
      }
      if (pieChartRef.current) {
        pieChartRef.current.data = calculateChartData(tickets).resolvedByData;
        pieChartRef.current.update();
      }
    }, [tickets]);
  
    useEffect(() => {
      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
        if (pieChartRef.current) {
          pieChartRef.current.destroy();
        }
      };
    }, []);
  
    if (!isOpen) return null;
  
    const chartData = calculateChartData(tickets);
  
    return (
      <div className="modal">
        <div className="modal-content">
          <h2>Statistiques ORY4 Housekeeping</h2>
          <div className="chart-container">
            <h3>Répartition status des tickets</h3>
            <Bar data={chartData} ref={chartRef} />
          </div>
          <div className="chart-container">
            <h3>Résolution de tickets par personne</h3>
            <Pie data={chartData.resolvedByData} ref={pieChartRef} />
          </div>
          {/* ... Other JSX ... */}
          <button onClick={onClose}>Fermer</button>
        </div>
      </div>
    );
  };
  

export default StatsModal;
